import { NavLink, useLocation } from 'react-router-dom';

function Navbar() {
  const { pathname } = useLocation();

  return (
    <div className='tabs is-centered'>
      <ul>
        <li className={pathname === '/' ? 'is-active' : ''}>
          <NavLink to='/'>Texto a Braille</NavLink>
        </li>
        <li className={pathname === '/braille-typewriter' ? 'is-active' : ''}>
          <NavLink to='/braille-typewriter'>Maquina de Escribir</NavLink>
        </li>
        <li className={pathname === '/under-construction' ? 'is-active' : ''} >
          <NavLink to='/under-construction'>Matemáticas</NavLink>
        </li>
      </ul>
    </div>
  );
}

export { Navbar };
