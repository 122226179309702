import React from 'react';

import '../styles/Root.scss';
import { Editor } from '../components/Editor'

function Root() {
  return (
    <main className='main'>
      <Editor />
    </main>
  );
}

export default Root;
