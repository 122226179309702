import React, { useRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import parse from 'html-react-parser';
import '../styles/Editor.scss'

import { toBraille } from '../utils/brailleConversor';

function Editor() {
  const [editorState, setEditorState] = useState('');
  const editor = useRef();

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const handleChange = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const bodyElement = doc.body;
    modifyTextNodes(bodyElement);
    const modifiedBodyHtmlString = bodyElement.innerHTML;
    setEditorState(modifiedBodyHtmlString)
  }

  const modifyTextNodes = (node) => {
    const bastardillaNodeNames = ['STRONG', 'EM', 'DEL', 'U'];
    if (bastardillaNodeNames.includes(node.nodeName)) {
      node.textContent = `☺${node.textContent}☺`
    }
    if (node.nodeType === Node.TEXT_NODE) {
      node.nodeValue = toBraille(node.nodeValue);
    } else {
      node.childNodes.forEach(modifyTextNodes);
    }
  }

  return (
    <div className='section is-small'>
      <div className='columns is-all-editor-wrapper'>
        <div className="column is-toolbar-wrapper sun-editor" id="toolbar_container" />
        <div className='column is-editor-wrapper'>
          <SunEditor
            lang='es'
            getSunEditorInstance={getSunEditorInstance}
            onChange={handleChange}
            setDefaultStyle='font-size: 16px;'
            autoFocus={true}
            height='100%'
            setOptions={{
              minHeight: '400px',
              toolbarContainer: "#toolbar_container",
              buttonList: [[
                'fontSize',
                'formatBlock',
                'bold', 'underline', 
                'italic', 'strike', 
                'subscript', 'superscript',
                'fontColor', 'hiliteColor',
                'indent', 'outdent',
                'align', 'lineHeight',
                'undo', 'redo',
                'removeFormat', 'save',
              ]],
            }}
          />
        </div>
        <div className='column is-reader-wrapper'>
          <div className='reader'>
            {parse(editorState)}
          </div>
        </div>
      </div>
    </div>
  );
}

export { Editor };
