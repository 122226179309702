import { useState } from 'react';

import { binaryToBraille } from '../utils/brailleConversor';
import '../styles/BrailleTypewriter.scss'

const BrailleTypewriter = () => {
  const [dotsRaised, setDotsRaised] = useState([false, false, false, false, false, false]);
  const [brailleText, setBrailleText] = useState('');

  const [isTyping, setIsTyping] = useState(false);

  const handleKeyDown = (event) => {
    const { key } = event;
    if (key === ' ') {
      setBrailleText(brailleText + binaryToBraille(0));
    } else if (key === 'Backspace') {
      setBrailleText(brailleText.slice(0, -1));
    } else if (key === 'Enter') {
      setBrailleText(brailleText + '\n');
    } else {
      const dotIndex = getDotIndexForKey(key);
      if (dotIndex !== -1) {
        const newDotsRaised = [...dotsRaised];
        newDotsRaised[dotIndex] = true;
        if (!isTyping) setIsTyping(true);
        setDotsRaised(newDotsRaised);
      }
    }
  };

  const handleKeyUp = (event) => {
    const { key } = event;
    const dotIndex = getDotIndexForKey(key);
    if (dotIndex !== -1) {
      const newDotsRaised = [...dotsRaised];
      newDotsRaised[dotIndex] = false;
      if (isTyping) {
        const binaryString = dotsRaised.map(raised => raised ? '1' : '0').join('');
        setBrailleText(brailleText + binaryToBraille(parseInt(binaryString, 2)));

        setIsTyping(false);
      }
      setDotsRaised(newDotsRaised);
    }
  };

  const getDotIndexForKey = (key) => {
    switch (key) {
      case 'l':
        return 0; // Dot 6
      case 'k':
        return 1; // Dot 5
      case 'j':
        return 2; // Dot 4
      case 's':
        return 3; // Dot 3
      case 'd':
        return 4; // Dot 2
      case 'f':
        return 5; // Dot 1
      default:
        return -1; // Invalid key
    }
  };

  const renderBrailleCell = () => {
    const keyMapping = [3, 2, 1, 4, 5, 6];
    return dotsRaised.map((raised, index) => (
      <div key={index} className={`dot ${raised ? 'raised' : 'lowered'}`}>{keyMapping[index]}</div>
    ));
  };

  return (
    <div className='section'>
      <textarea
        className='braille-output textarea has-fixed-size'
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        rows={10}
        autoFocus
        value={brailleText}
        onChange={() => { }}
      />
      <div className='braille-machine'>
        <div className='braille-cell'>
          <div className='dot-container'>
            {renderBrailleCell()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrailleTypewriter;
